export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';


// export const   GET_TOKEN ='GET_TOKEN';
// export const    GET_USER ='GET_USER';
// export const GET_ALL_USERS ='GET_ALL_USERS';

const ACTIONS = {
    LOGIN: 'LOGIN',
    GET_TOKEN: 'GET_TOKEN',
    GET_USER: 'GET_USER',
    GET_ALL_USERS: 'GET_ALL_USERS'
}

export default ACTIONS


