import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import './ath.css'
import Icon from './icon';
import { signin, signup } from '../../actions/auth';
import { AUTH } from '../../constants/actionTypes';
import useStyles from './styles';
import Input from './Input';

const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };

const SignUp = () => {
  const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const switchMode = () => {
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSignup) {
      dispatch(signup(form, history));
    } else {
      dispatch(signin(form, history));
    }
  };

  const googleSuccess = async (res) => {
    const result = res?.profileObj;
    const token = res?.tokenId;

    try {
      dispatch({ type: AUTH, data: { result, token } });

      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  const googleError = () => console.log('Google Sign In was unsuccessful. Try again later');

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={6}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography id='arab100' className={classes.Typography} component="h1" variant="h5">{ isSignup ? 'إنشاء حساب جديد' : 'تسجـيل الدخول' }</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid  container spacing={2}>
            { isSignup && (
            <>
              <Input  name="firstName" label="الاسم الاول " handleChange={handleChange} autoFocus half />
              <Input  name="lastName" label=" الاسم الاخير" handleChange={handleChange} half />
            </>
            )}
            <Input   style={{  direction: 'rtl' }} name="email" label="البريد الالكتروني" handleChange={handleChange} type="email" />
            <Input  name="password" label="الرقم السري" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            { isSignup && <Input  name="confirmPassword" label="تأكيد الرقم السري" handleChange={handleChange} type="password" /> }
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            { isSignup ? 'إنشاء حساب جديد' : 'تسجـيل الدخول' }
          </Button>
        
          <GoogleLogin
  clientId="564033717568-bu2nr1l9h31bhk9bff4pqbenvvoju3oq.apps.googleusercontent.com"
  onSuccess={googleSuccess}
  onFailure={googleError}
  cookiePolicy="single_host_origin"
  render={(renderProps) => (
    <Button
      className={classes.googleButton}
      color="primary"
      fullWidth
      onClick={renderProps.onClick}
      disabled={renderProps.disabled}
      startIcon={<Icon />}
      variant="contained"
    >
      Sign in with Google
    </Button>
  )}
  responseType="code"
  scope="openid email profile"
  accessType="offline"
  prompt="consent"
  includeGrantedScopes={true}
/>

          <Grid container justify="flex-end">
            <Grid item>
              <Button onClick={switchMode}>
                { isSignup ? 'هل لديك حساب؟ تسجيل الدخول' : "ليس لديك حساب؟ اشتراك" }
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default SignUp;
